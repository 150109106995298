import { useContext } from 'react';
import moment from 'moment';
import {
    Row,
    Col,
    DatePicker,
    Input,
    Select,
    Button,
    Space,
} from 'antd';
import { IContext } from '../context/invoices.interface';
import { InvoicesContext } from '../context/invoices.context';
import { InvoicesStatus } from '../../../const/invoices';
import SelectRenderColumns from '../../SelectRenderColumns';
import { COLUMN_OPTIONS, STORAGE_COLUMNS_NAME } from '../constants';
import BulkInvoiceStatus from './BulkInvoiceStatus';

const { Search } = Input;

const Filters = ({
    searchParams,
    handleDate,
    handleSearch,
    handleStatus,
    handleInvoiceId,
    handleFrequency,
    handleGateway,
    handleType,
}: Partial<IContext>) => {
    const {
        downloadInvoices,
        selectedRowKeys,
        date,
        selectedColumns,
        handleSelectedColumns,
    } = useContext(InvoicesContext);

    const hasSelected = selectedRowKeys && selectedRowKeys.length > 0;

    const search = searchParams?.get('search') || '';
    const invoiceId = searchParams?.get('invoiceId') || '';
    const status = JSON.parse(searchParams?.get('status') || '[]');
    const frequency = JSON.parse(searchParams?.get('frequency') || '[]');
    const type = JSON.parse(searchParams?.get('type') || '[]');
    const gateway = JSON.parse(searchParams?.get('gateway') || '[]');

    const STATUS = Object.entries(InvoicesStatus).map(([key, value]) => ({
        label: value,
        value: key,
    }));

    const GATEWAYS = [
        { label: 'PAYMENTEZ', value: 'PAYMENTEZ' },
        { label: 'STRIPE', value: 'STRIPE' },
        { label: 'EPAYCO', value: 'EPAYCO' },
    ];

    const FREQUENCIES = [
        { label: 'WEEKLY', value: 'WEEKLY' },
        { label: 'FORTNIGHTLY', value: 'FORTNIGHTLY' },
        { label: 'MONTHLY', value: 'MONTHLY' },
    ];

    const TYPES = [
        { label: 'MRR', value: 'MRR' },
    ];

    return (
        <Row justify="start" gutter={[16, 24]}>
            <Col>
                <DatePicker value={date ? moment(date) : undefined} onChange={handleDate} />
            </Col>
            <Col>
                <Select
                    defaultValue={status}
                    mode="multiple"
                    style={{ width: '160px' }}
                    placeholder="Status"
                    onChange={handleStatus}
                    options={STATUS}
                    maxTagCount="responsive"
                />
            </Col>
            <Col>
                <Select
                    allowClear
                    defaultValue={gateway}
                    mode="multiple"
                    style={{ width: '175px' }}
                    placeholder="Payment Gateways"
                    onChange={handleGateway}
                    options={GATEWAYS}
                    maxTagCount="responsive"
                />
            </Col>
            <Col>
                <Select
                    defaultValue={frequency}
                    mode="multiple"
                    style={{ width: '160px' }}
                    placeholder="Frequency"
                    onChange={handleFrequency}
                    options={FREQUENCIES}
                    maxTagCount="responsive"
                />
            </Col>
            <Col>
                <Select
                    defaultValue={type}
                    mode="multiple"
                    style={{ width: '160px' }}
                    placeholder="Type"
                    onChange={handleType}
                    options={TYPES}
                    maxTagCount="responsive"
                />
            </Col>
            <Col>
                <SelectRenderColumns
                    options={COLUMN_OPTIONS}
                    value={selectedColumns}
                    localStorageName={STORAGE_COLUMNS_NAME}
                    setColumns={handleSelectedColumns}
                />
            </Col>
            <Col>
                <Search
                    enterButton
                    style={{ width: '350px' }}
                    placeholder="Search by workspace, plan or workspaceUniqueID"
                    defaultValue={search}
                    onSearch={handleSearch}
                />
            </Col>
            <Col>
                <Search
                    allowClear
                    enterButton
                    placeholder="Invoice number"
                    defaultValue={invoiceId}
                    onSearch={handleInvoiceId}
                />
            </Col>
            {
                hasSelected && (
                    <Space size={0}>
                        <Col>
                            <Button
                                type="primary"
                                onClick={downloadInvoices}
                            >
                                Download
                            </Button>
                        </Col>
                        <Col>
                            <BulkInvoiceStatus />
                        </Col>
                    </Space>
                )
            }
        </Row>
    );
};

export default Filters;
